import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Utils/Firebase";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { Button } from "@mui/material";
import { fetchUserData } from "../../Utils/Firebase";
import { RiShareForwardLine } from "react-icons/ri";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import "./Profile.css";
import list1 from "../../images/list1.png";
import list2 from "../../images/list2.png";
import list3 from "../../images/list3.png";
import list4 from "../../images/list4.png";

export default function Profile() {
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [userVideos, setUserVideos] = useState([]);
  const [loadings, setLoadings] = useState(true);
  const [open, setOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const pop = Boolean(anchorEl);

  const handleOpen = (res) => {
    const baseUrl =
      window.location.href.split("/").slice(0, 3).join("/") + "#" + res;
    setShareLink(baseUrl);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchUserData(user.uid).then((data) => {
      setUserVideos(data);
      setLoadings(false);
    });
  }, [user, loading, navigate]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    color: "#000",
    bgcolor: "#fff",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      {!loadings ? (
        <div className="profile-div">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Share Link
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Copy this link with your friends to share your video.
                <br />
                {shareLink}
              </Typography>
            </Box>
          </Modal>
          <div className="profile-back">
            <ArrowBackOutlinedIcon onClick={() => navigate("/")} />
          </div>
          <div className="profile-logo">
            <img src={user.photoURL} alt="profile" />
            <div className="user-name">@{user.displayName}</div>
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="user-info">
              <div className="info">
                <div className="text-1">15</div>
                <div className="text-2">Subscribers</div>
              </div>
              <div className="info">
                <div className="text-1">339.2k</div>
                <div className="text-2">Subscribers</div>
              </div>
              <div className="info">
                <div className="text-1">3.6M</div>
                <div className="text-2">Subscribers</div>
              </div>
            </div>
          </div> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="profile-buttons">
              <div>
                <Button
                  className="p-earn"
                  variant="contained"
                  onClick={() => {
                    navigate("/upload");
                  }}
                >
                  Upload and earn &nbsp; <AiOutlineDollarCircle />
                </Button>
              </div>
              {/* <Button className="p-edit" variant="contained">
                Edit Profile
              </Button> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="info-text">
              Upload 30 secs video, share with friends.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="profile-list">
              <div className="list-item">
                <img src={list1} alt="list1" onClick={handlePopOpen} />
              </div>
              <div className="list-item">
                <img src={list2} alt="list2" onClick={handlePopOpen} />
              </div>
              <div className="list-item">
                <img src={list3} alt="list3" onClick={handlePopOpen} />
              </div>
              <div className="list-item">
                <img src={list4} alt="list4" onClick={handlePopOpen} />
              </div>
              <Popover
                open={pop}
                anchorEl={anchorEl}
                onClose={handlePopClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  Coming soon.
                </Typography>
              </Popover>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="p-videos">
              {userVideos.map((video) => (
                <div className="video-item" key={video.id}>
                  <p style={{ display: "none" }}>{video.data().desc}</p>
                  <video className="small-video" controls preload="metadata">
                    <source src={video.data().downloadURL} type="video/mp4" />
                  </video>
                  <div
                    className="share-video"
                    onClick={() => {
                      handleOpen(video.id);
                    }}
                  >
                    <RiShareForwardLine className="share-icon" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">Loading...</div>
      )}
    </>
  );
}
