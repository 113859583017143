import React, { useState, useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-resolution-switcher";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { 
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { RiShareForwardLine } from "react-icons/ri";
import useElementOnScreen from "./OnScreen";
import Slider from "@mui/material/Slider";
import "./Video.css";

export default function Video({ video, image, title, link, desc, amazonLink }) {
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [played, setPlayed] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.3,
  };
  const isVisibile = useElementOnScreen(options, videoRef);

  const onVideoClick = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(!playing);
    } else {
      videoRef.current.play();
      setPlaying(!playing);
    }
  };

  useEffect(() => {
    playerRef.current = videojs(videoRef.current, {
      controls: true,
      autoplay: false,
      poster: false,
      preload: "auto",
      muted: true,
      loop: true,
      fluid: false,
      responsive: false,
      sources: [
        {
          src: video,
          type: "video/mp4",
        },
      ],
      plugins: {
        videoJsResolutionSwitcher: {
          default: "auto",
          label: "Quality",
        },
      },
    });
    playerRef.current.on("play", () => {
      setPlaying(true);
    });
    playerRef.current.on("pause", () => {
      setPlaying(false);
    });
    playerRef.current.on("timeupdate", () => {
      setPlayed(
        (playerRef.current.currentTime() / playerRef.current.duration()) * 100
      );
    });
    return () => {
      playerRef.current.dispose();
    };
  }, [video]);

  useEffect(() => {
    if (isVisibile) {
      if (!playing) {
        videoRef.current.play();
        setPlaying(true);
      }
    } else {
      if (playing) {
        videoRef.current.pause();
        setPlaying(false);
      }
    }
  }, [isVisibile]);

  const shareLink = window.location.href.split("/").slice(0, 3).join("/") + "#" + link;

  return (
    <>
      <div className="video">
        <Dialog
          open={drawer}
          onClose={() => setDrawer(false)}
          fullScreen
          PaperProps={{
            sx: {
              width: "800px",
              height: "250px",
              position: "absolute",
              bottom: "0.5%",
              m: 0,
            },
          }}
        >
          <DialogTitle style={{marginBottom:"30px"}}>
            <Typography variant="h2">
              <div
                className="title"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Send
              </div>
              <IconButton
                onClick={() => setDrawer(false)}
                style={{
                  position: "absolute",
                  right: "3px",
                  top: "4px",
                }}
              >
                <CloseIcon />
              </IconButton>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <EmailShareButton className="share-button" url={shareLink}>
                <EmailIcon size={45} round /> <br />
                <span className="text">Email</span>
              </EmailShareButton>
              <FacebookShareButton className="share-button" url={shareLink}>
                <FacebookIcon size={45} round /> <br />
                <span className="text">Facebook</span>
              </FacebookShareButton>
              <FacebookMessengerShareButton className="share-button" url={shareLink}>
                <FacebookMessengerIcon size={45} round /> <br />
                <span className="text">Messenger</span>
              </FacebookMessengerShareButton>
              <TelegramShareButton className="share-button" url={shareLink}>
                <TelegramIcon size={45} round /> <br />
                <span className="text">Telegram</span>
              </TelegramShareButton>
              <TwitterShareButton className="share-button" url={shareLink}>
                <TwitterIcon size={45} round /> <br />
                <span className="text">Twitter</span>
              </TwitterShareButton>
              <WhatsappShareButton className="share-button" url={shareLink}>
                <WhatsappIcon size={45} round /> <br />
                <span className="text">Whatsapp</span>
              </WhatsappShareButton>
            </Typography>
          </DialogContent>
        </Dialog>

        <video ref={videoRef} className="video-player"></video>
        <p style={{display:"none"}}>{desc}</p>
        <div className="play">
          {playing ? (
            <PauseIcon sx={{ fontSize: "70px" }} onClick={onVideoClick} />
          ) : (
            <PlayArrowIcon sx={{ fontSize: "70px" }} onClick={onVideoClick} />
          )}
        </div>
        <Button className="share" onClick={() => setDrawer(true)}>
          <RiShareForwardLine />
        </Button>
        <div className="product">
          <a href={amazonLink} target="_blank" rel="noopener noreferrer"> <img src={image} alt="product" /></a>
          <div className="name">{title}</div>
        </div>
        <div className="button">
          <a
            className="sell-button"
            href={amazonLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Buy on amazon
          </a>
        </div>
        <div className="mute">
          {muted ? (
            <VolumeOffIcon
              sx={{ fontSize: "40px" }}
              onClick={() => {
                setMuted(false);
                videoRef.current.muted = false;
              }}
            />
          ) : (
            <VolumeUpIcon
              sx={{ fontSize: "40px" }}
              onClick={() => {
                setMuted(true);
                videoRef.current.muted = true;
              }}
            />
          )}
        </div>
        <div className="video-slider">
          <Slider
            style={{ width: "95%", color: "red" }}
            value={played}
            onChange={(e, value) => {
              videoRef.current.currentTime =
                (value / 100) * videoRef.current.duration;
            }}
            aria-labelledby="continuous-slider"
            min={0}
            max={100}
          />
        </div>
      </div>
    </>
  );
}
