import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useReactMediaRecorder } from "react-media-recorder";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import { AiOutlineClose } from "react-icons/ai";
import Webcam from "react-webcam";
import { useAuthState } from "react-firebase-hooks/auth";
import { uploadVideo, auth } from "../../Utils/Firebase";
import recordButton from "../../images/record_button.png";
import "./Record.css";

export default function Record(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { status, startRecording, stopRecording, mediaBlobUrl, previewStream } =
    useReactMediaRecorder({ video: true, audio: true });
  const [disabled, setDisabled] = useState(true);
  const [playing, setPlaying] = useState(true);
  const [played, setPlayed] = useState(0);
  const myPlayerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [vloading, setVLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    navigate("/profile");
  };
  const [user, loading, error] = useAuthState(auth);
  const [shareLink, setShareLink] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    color: "#000",
    bgcolor: "#fff",
    border: "1px solid #fff",
    boxShadow: 24,
    p: 4,
  };

  const VideoPreview = ({ stream }) => {
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);

    if (!stream) {
      return null;
    }

    return (
      <video
        className="recording-video"
        ref={videoRef}
        autoPlay
        controls={false}
      />
    );
  };

  // Make sure to uncomment this out  
  useEffect(() => {
    if (!user) return navigate("/");
  }, [user, navigate]);

  useEffect(() => {
    if (status === "stopped") {
      setDisabled(false);
    }
  }, [status]);

  const handleClick = async () => {
    if (disabled) return;
    const mediaBlob = await fetch(mediaBlobUrl).then((response) =>
      response.blob()
    );
    // remember to uncomment
    // const user_id = "testuser";
    const user_id = user.uid;
    const res = await uploadVideo(
      mediaBlob,
      state.product,
      state.type,
      state.desc,
      user_id
    );
    // get window base url without all subdomains
    const baseUrl =
      window.location.href.split("/").slice(0, 3).join("/") + "#" + res;
    setShareLink(baseUrl);
    handleOpen();
  };

  const handleAlert = () => {
    alert("Please allow the browser to access your camera and microphone.");
    navigate("/upload");
  };

  const handleStartRecording = () => {
    startRecording();
    setTimeout(() => {
      if(status === "recording") {
        stopRecording();
      }
    }, 30000);
  };

  return (
    <>
      {!vloading ? (
        <div className="record-div">
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Share Link
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Copy this link with your friends to share your video.
                <br />
                {shareLink}
              </Typography>
            </Box>
          </Modal>
          <div className="screen">
            {status === "idle" && (
              <div className="idle-screen">
                <Webcam className="idle-video" onUserMediaError={handleAlert} />
                <div className="close-idle" onClick={() => navigate("/upload")}>
                  <AiOutlineClose className="close-icon" />
                </div>
                <div className="record-button" onClick={handleStartRecording}>
                  <img src={recordButton} alt="record" />
                </div>
              </div>
            )}
            {status === "recording" && (
              <div className="recording-screen">
                <VideoPreview stream={previewStream} />
                <div
                  className="close-recording"
                  onClick={() => navigate("/upload")}
                >
                  <AiOutlineClose className="close-icon" />
                </div>
                <div className="pause-button" onClick={stopRecording}>
                  <img src={recordButton} alt="pause" />
                </div>
              </div>
            )}
            {status === "stopped" && (
              <div className="stopped-screen">
                <video
                  className="stopped-video"
                  src={mediaBlobUrl}
                  controls={false}
                  ref={myPlayerRef}
                  autoPlay
                  loop
                  onTimeUpdate={() => {
                    setPlayed(
                      (myPlayerRef.current.currentTime /
                        myPlayerRef.current.duration) *
                        100
                    );
                  }}
                />
                <div
                  className="close-stopped"
                  onClick={() => navigate("/upload")}
                >
                  <Button className="back-button">Retake</Button>
                </div>
                <Button
                  className="upload-button"
                  disabled={disabled}
                  onClick={handleClick}
                >
                  Upload And Make Public
                </Button>
                <div className="play">
                  {playing ? (
                    <PauseIcon
                      sx={{ fontSize: "70px" }}
                      onClick={() => {
                        myPlayerRef.current.pause();
                        setPlaying(false);
                      }}
                    />
                  ) : (
                    <PlayArrowIcon
                      sx={{ fontSize: "70px" }}
                      onClick={() => {
                        myPlayerRef.current.play();
                        setPlaying(true);
                      }}
                    />
                  )}
                </div>
                <div className="video-slider">
                  <Slider
                    style={{ width: "95%", color: "red" }}
                    min={0}
                    max={100}
                    value={played}
                    onChange={(e, value) => {
                      myPlayerRef.current.currentTime =
                        (value / 100) * myPlayerRef.current.duration;
                    }}
                    aria-labelledby="continuous-slider"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="vloader">Loading...</div>
      )}
    </>
  );
}
