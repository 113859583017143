import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home"
import Profile from "./components/Profile/Profile";
import Upload from "./components/Upload/Upload";
import Record from "./components/Record/Record";
import PageNotFound from './components/404Page/PageNotFound';

import TagManager from 'react-gtm-module'
const tagManagerArgs = {
    gtmId: 'GTM-N86GVNT'
}
TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/upload" element={<Upload />} />
        <Route exact path="/record" element={<Record />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
