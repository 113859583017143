import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../Logo/Logo";
import "./Navbar.css";

export default function Navbar({ onClick, onPhoneFilter, onRangeFilter, onCategoryFilter }) {
  return (
    <div className="nav-div">
      <AppBar position="static" className="navbar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onClick}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Logo />
          </Typography>
          <Button className="sell-button" onClick={onClick}>Sell & earn</Button>
        </Toolbar>
        <div className="filters">
          <select className="filter" onChange={onPhoneFilter}>
            <option value="">Category</option>
            <option value="phones">Phones</option>
            <option value="laptops">Laptops</option>
            <option value="accessories">Accessories</option>
            <option value="tvs">TVs</option>
          </select>
          <select className="filter" onChange={onRangeFilter}>
          <option value="">Sub-Category</option>
            <option value="mid-range">Mid-Range Phones</option>
            <option value="high-range">High-Range Phones</option>
            <option value="low-range">Low-Range Phones</option>
          </select>
          <select className="filter" onChange={onCategoryFilter}>
            <option value="">Type</option>
            <option value="comparison">Comparisons</option>
            <option value="review">Review</option>
          </select>
        </div>
      </AppBar>
    </div>
  );
}
