import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { fetchProductList } from "../../Utils/Firebase";
import "./Upload.css";

export default function Upload() {
  const navigate = useNavigate();
  const [category, setCategory] = useState("phones");
  const [productData, setProductData] = useState([]);
  const [product, setProduct] = useState("");
  const [subCategory, setSubCategory] = useState("mid-range");
  const [type, setType] = useState("comparison");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    fetchProductList().then((data) => {
      let new_data = [];
      data.map((item) => {
        if (item.data().category === category && item.data().subcategory === subCategory) {
          new_data.push(item);
        }
      });
      setProductData(new_data);
      setLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [category, subCategory]);

  const handleClick = () => {
    if(product === ""){
      setErrorMessage("Please select a product");
      setError(true);
    }
    else if(desc === ""){
      setErrorMessage("Please enter a description");
      setError(true);
    }
    else{
    navigate("/record", {
      state: { product, type, desc },
    });
    }
  };

  return (
    <>
      {!loading ? (
        <div className="upload-div">
          <div className="header">
            <div className="cancel" onClick={() => navigate("/profile")}>
              Cancel
            </div>
            <div className="upload-text">Fill in the fields</div>
            <Button
              className="upload-button"
              // disabled={disabled}
              onClick={handleClick}
            >
              Upload
            </Button>
          </div>
          <div className="category">
            <div className="small-text">Choose a product category</div>
            <select
              className="select"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="phones">Phones</option>
              <option value="laptops">Laptops</option>
              <option value="accessories">Accessories</option>
              <option value="tvs">TVs</option>
            </select>
          </div>
          <div className="sub-category">
            <div className="small-text">Choose sub-category</div>
            <select
              className="select"
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
            >
              <option value="mid-range">Mid-Range</option>
              <option value="high-range">High-Range</option>
              <option value="low-range">Low-Range</option>
            </select>
          </div>
          <div className="product">
            <div className="small-text">Choose product name</div>
            <select
              className="select"
              value={product}
              onChange={(e) => setProduct(e.target.value)}
            >
              <option value="">select</option>
              {productData.map((item) => (
                <option key={item.id} value={item.id + " " + item.data().product_url}>
                  {item.data().product_name} &nbsp; {item.data().commission_rate}% commission per sale
                </option>
              ))}
            </select>
          </div>
          <div className="type">
            <div className="small-text">Choose type</div>
            <select
              className="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="comparison">Comparison</option>
              <option value="review">Review</option>
            </select>
          </div>
          <div className="desc">
            <div className="small-text">Product description</div>
            <textarea
              className="textarea"
              placeholder="Product description"
              value={desc}
              rows="8"
              cols="40"
              onChange={(e) => setDesc(e.target.value)}
            />
          </div>
          {error ? <div className="error">{errorMessage}</div> : null}
        </div>
      ) : (
        <div className="loading">Loading...</div>
      )}
    </>
  );
}
