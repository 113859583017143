import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { FcGoogle } from "react-icons/fc";
import { signInWithGoogle, auth } from "../../Utils/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Logo from "../Logo/Logo";
import "./Burger.css";

export default function Burger({ onClick }) {

  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if( loading )
      return;
    if (user) {
      navigate("/profile");
    }
  }, [user, loading, navigate]);

  return (
    <div className="burger-div">
      <AppBar position="static" className="burger-bar">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onClick}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Logo />
          </Typography>
        </Toolbar>
      </AppBar>

      <div className="heading">
        <div className="heading-text">Register on Enyye</div>
        <div className="subheading-text">
          Create a profile to follow other accounts, upload your own videos and
          get activity bonuses.
        </div>
      </div>

      <div className="login" onClick={signInWithGoogle}>
        <FcGoogle className="login-logo" />
        <span className="login-text">Continue to Google</span>
      </div>
    </div>
  );
}
