import React, { useState, useEffect } from "react";
import { isIOS, isSafari } from "react-device-detect";
import Navbar from "../Navbar/Navbar";
import Image1 from "../../images/image_1.png";
import Video from "../Video/Video";
import Burger from "../Burger/Burger";
import { fetchData, fetchDataByID } from "../../Utils/Firebase";
import "./Home.css";

export default function Home() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataId, setDataId] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState("");
  const [rangeFilter, setRangeFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");

  const alt_vid = (isIOS | isSafari) ? 1 : 0;
  console.log(alt_vid);
  useEffect(() => {
    fetchData()
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (window.location.href.includes("#")) {
      const loc = window.location.href.split("#")[1];
      fetchDataByID(loc)
        .then((res) => {
          setDataId(res);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  }, []);

  const [openBurger, setOpenBurger] = useState(false);
  const handleBurger = () => {
    setOpenBurger(!openBurger);
  };
  const handlePhoneFilter = (e) => {
    setPhoneFilter(e.target.value);
  };
  const handleRangeFilter = (e) => {
    setRangeFilter(e.target.value);
  };
  const handleCategoryFilter = (e) => {
    setCategoryFilter(e.target.value);
  };

  return (
    <>
      {openBurger ? (
        <Burger onClick={handleBurger} />
      ) : !loading ? (
        <div className="home-div">
          <Navbar
            onClick={handleBurger}
            onPhoneFilter={handlePhoneFilter}
            onRangeFilter={handleRangeFilter}
            onCategoryFilter={handleCategoryFilter}
          />
          <div className="video-div">
            <div className="home-videos">
              {dataId.length > 0 ? (
                <Video
                  key={dataId[0].id}
                  // video={dataId[0].downloadURL}
                  video={(isIOS | isSafari) ? dataId[0].downloadURL2 : dataId[0].downloadURL}
                  // video={dataId[0].downloadURL2}
                  image={dataId[0].image}
                  title={dataId[0].name}
                  link={dataId[0].id}
                  amazonLink={dataId[0].link}
                  desc={dataId[0].desc}
                />
              ) : null}
              <p>{isIOS | isSafari}</p>
              
              {phoneFilter !== "" || rangeFilter !== "" || categoryFilter !== ""
                ? data
                    .filter(
                      (item) =>
                        item.category === phoneFilter ||
                        item.subCategory === rangeFilter ||
                        item.type === categoryFilter
                    )
                    .map((item) => (
                      <Video
                        key={item.id}
                        // video={item.downloadURL}
                        video={(isIOS | isSafari) ? item.downloadURL2 : item.downloadURL}
                        // video={item.downloadURL2}
                        image={item.image}
                        title={item.name}
                        link={item.id}
                        amazonLink={item.link}
                        desc={item.desc}
                      />
                    ))
                : data.map((video) => (
                    <Video
                      key={video.id}
                      // video={video.downloadURL2}
                      video={(isIOS | isSafari) ? video.downloadURL2 : video.downloadURL}
                      image={video.image}
                      title={video.name}
                      link={video.id}
                      amazonLink={video.link}
                      desc={video.desc}
                    ></Video>
                  ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">Loading...</div>
      )}
    </>
  );
}
