import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);

export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const id = uuidv4();
    const docRefs = collection(db, "available_tracking_ids");
    const q = query(docRefs, where("available", "==", true), limit(1));
    const docs = await getDocs(q);
    let docData = [];
    docs.forEach((doc) => {
      docData.push(doc);
    });
    // const tracking_id = docData[0].data().tracking_id;
    const tracking_id = id;
    // const udoc = doc(db, "available_tracking_ids", docData[0].id);
    // await updateDoc(udoc, {
    //   available: false,
    //   assigned_user: user.uid,
    // });
    const docRef = collection(db, "users");
    addDoc(docRef, {
      id: user.uid,
      tracking_id: tracking_id,
    });
    return user;
  } catch (err) {
    console.log(err);
  }
};

export const uploadVideo = async (file, product, type, desc, user_id) => {
  let idRef;
  try {
    const fileName = uuidv4();
    const storageRef = ref(storage, "videos/" + fileName);
    const videoURL = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    const product_id = product.split(" ")[0];
    const prodRef = doc(db, "products", product_id);
    const product_db = await getDoc(prodRef);
    const tracking_id = "blogmonetize-21"
    const product_img = product_db.data().product_img;
    const base_url = product_db.data().product_url;
    const amazon_url = base_url + "&ref=" + tracking_id;
    const docRef = collection(db, "videoData");
    idRef = await addDoc(docRef, {
      downloadURL: downloadURL,
      product_id: product_id,
      type: type,
      desc: desc,
      user_id: user_id,
      product_img: product_img,
      amazon_url: amazon_url,
    });
    return idRef.id;
  } catch (err) {
    console.log(err);
  }
};

export const fetchData = async () => {
  try {
    const docRef = collection(db, "videoData");
    const productRef = collection(db, "products");

    // const docsq = query(docRef);
    // const docsq = query(docRef, where("qualify","==",true));
    const docsq = query(docRef, orderBy("quality_score","desc"));
    const productq = query(productRef);

    const docs = await getDocs(docsq);
    const product = await getDocs(productq);

    

    let data = [];
    docs.forEach((doc) => {
      product.forEach((p) => {
        if (p.id === doc.data().product_id) {
          data.push({
            id: doc.id,
            downloadURL: doc.data().downloadURL,
            downloadURL2: doc.data().downloadURL2,
            link: doc.data().amazon_url,
            image: doc.data().product_img,
            name: p.data().product_name,
            category: p.data().category,
            subCategory: p.data().subcategory,
            type: doc.data().type,
            desc: doc.data().desc,
          });
        }
      });
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchUserData = async (user_id) => {
  try {
    const docRef = collection(db, "videoData");
    const docs = await getDocs(docRef);
    let data = [];
    docs.forEach((doc) => {
      if (doc.data().user_id === user_id) {
        data.push(doc);
      }
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchDataByID = async (id) => {
  try {
    const docRef = collection(db, "videoData");
    const productRef = collection(db, "products");
    const docs = await getDocs(docRef);
    const product = await getDocs(productRef);
    let data = [];
    docs.forEach((doc) => {
      if (doc.id === id) {
        product.forEach((p) => {
          if (p.id === doc.data().product_id) {
            data.push({
              id: doc.id,
              downloadURL: doc.data().downloadURL,
              downloadURL2: doc.data().downloadURL2,
              link: doc.data().amazon_url,
              image: doc.data().product_img,
              name: p.data().product_name,
              category: p.data().category,
              subCategory: p.data().subcategory,
              type: doc.data().type,
              desc: doc.data().desc,
            });
          }
        });
      }
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const fetchProductList = async () => {
  try {
    const docRef = collection(db, "products");
    const docs = await getDocs(docRef);
    let data = [];
    docs.forEach((doc) => {
      data.push(doc);
    });
    return data;
  } catch (e) {
    console.error(e);
  }
};

export const logout = () => {
  signOut(auth);
};
